import { useEffect, useState } from "react";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import { FaArrowCircleRight } from 'react-icons/fa';

function BookCar() {
    const firestore = getFirestore();

    // Booking car state
    const [carType, setCarType] = useState("");
    const [carName, setCarName] = useState("");
    const [carImg, setCarImg] = useState("");
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    
    // Vehicle details state
    const [vehicleDetailsData, setVehicleDetailsData] = useState([]);

    useEffect(() => {
        fetchVehicleDetails();
    }, []);

    const fetchVehicleDetails = async () => {
        const vehiclesCollection = collection(firestore, "vehicles");
        const vehiclesSnapshot = await getDocs(vehiclesCollection);
        const vehiclesData = vehiclesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setVehicleDetailsData(vehiclesData);
    };

    const handleCar = (e) => {
        setCarType(e);
        const selectedVehicle = vehicleDetailsData.find((data) => data.id === e.value);
        setCarImg(selectedVehicle?.imageUrl || "");
        setCarName(selectedVehicle?.vehicleName || "");
    };

    const handleName = (e) => setName(e.target.value);
    const handlePhone = (e) => setPhone(e.target.value);
    const handleEmail = (e) => setEmail(e.target.value);
    const handleMessage = (e) => setMessage(e.target.value);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const regex = /^(?:\+?61|0)(?:\d{9}|\d{2}\s?\d{4}\s?\d{4})$/;
        return regex.test(phoneNumber);
    };

    const validateForm = () => {
      console.log('Validating form'); // Add this log
        if (!carType) {
            Swal.fire(" Select Your Car", "Please select a car type", "error");
            return false;
        }
        if (!name) {
            Swal.fire("Full Name", "Name is required", "error");
            return false;
        }
        if (!phone || !validatePhoneNumber(phone)) {
            Swal.fire("Phone Number", "Please enter a valid phone number", "error");
            return false;
        }
        if (!email || !validateEmail(email)) {
            Swal.fire("Email", "Please enter a valid email", "error");
            return false;
        }
        if (!message) {
            Swal.fire("Message", "Message is required", "error");
            return false;
        }
        return true;
    };

    const confirmBooking = async (e) => {
      console.log('Confirm booking function called'); 
        e.preventDefault();

        if (!validateForm()) return;

        const payload = {
          carType: carType.label,
          name: name,
          phone: phone,
          email: email,
          message: message,
        };

        try {
          const result = await emailjs.send(
            process.env.REACT_APP_EMAIL_SERVICE_ID,
            process.env.REACT_APP_EMAIL_TEMPLATE_ID,
            payload,
            process.env.REACT_APP_EMAIL_PUBLIC_KEY
        );

            console.log('Email sent successfully:', result); // Log the result for debugging

            Swal.fire({
                icon: "success",
                title: "Request Sent",
                text: "Thank you for enquiring. Our friendly staff will get back to you shortly!",
            });

            resetForm(); // Clear form fields
        } catch (error) {
          console.error('Error sending email:', error); // Log the error for debugging
            Swal.fire({
                icon: "error",
                title: "Failed to Send Request",
                text: "Something went wrong. Please try again later.",
            });
        }
    };

    const resetForm = () => {
        setCarType("");
        setName("");
        setPhone("");
        setEmail("");
        setMessage("");
    };

    const CarList = vehicleDetailsData.map((data) => ({
        value: data.id,
        label: data.vehicleName,
    }));

    useEffect(() => {
      emailjs.init(process.env.REACT_APP_EMAIL_PUBLIC_KEY);
      fetchVehicleDetails();
  }, []);
  

  console.log("Service ID:", process.env.REACT_APP_EMAIL_SERVICE_ID);
console.log("Template ID:", process.env.REACT_APP_EMAIL_TEMPLATE_ID);
console.log("Public Key:", process.env.REACT_APP_EMAIL_PUBLIC_KEY);


    return (
        <section id='booking-section' className='book-section'>
            <div className='book-content'>
                <div className='book-content__box'>
                <div className='book-content-bg'>
                    <h2 className='book-car-title'>
                        <span>Request a Quote</span>
                    </h2>
                    <form className='box-form' onSubmit={confirmBooking}>
                <div className='box-form__car-type'>
                  <label>
                    <i className='fa-solid fa-car'></i> &nbsp; Select Your Car
                  </label>
                  <Select
                    value={carType}
                    onChange={handleCar}
                    options={CarList}
                    placeholder='Select your car type'
                    isSearchable={true}
                    classNamePrefix="custom-select"
  className="custom-select-container"
  styles={{
    menu: (provided) => ({
        ...provided,
        maxHeight: '300px', // Set the maximum height for the dropdown
        overflowY: 'scroll', // Always show the vertical scrollbar
        zIndex: 1000, // Ensure it appears above other elements
    }),
 
}}
                  />
                </div>
                <div className='box-form__car-time'>
                  <label>
                    <i className='fa-regular fa-calendar-days '></i> &nbsp;Full
                    Name
                  </label>
                  <TextField
                    placeholder='Enter your name'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                     
                    value={name}
                    onChange={handleName}
                    sx={{ m: 0, cursor: "auto" }}
                  />
                </div>
                <div className='box-form__car-time'>
                  <label>
                    <i className='fa-regular fa-calendar-days '></i> &nbsp;Phone
                  </label>
                  <TextField
                    placeholder='+61 xxxx xxx xxx' //0400 920 512
                    variant='outlined'
                    margin='normal'
                    value={phone}
                    onChange={handlePhone}
                    type='text'
                    fullWidth
                     
                    error={Boolean(phone) && !validatePhoneNumber(phone)}  
                    helperText={
                      Boolean(phone) && !validatePhoneNumber(phone)
                        ? "Invalid phone number"
                        : ""
                    }
                    sx={{ m: 0 }}
                  />
                </div>
                <div className='box-form__car-time'>
                  <label>
                    <i className='fa-regular fa-calendar-days '></i> &nbsp;Email
                  </label>
                  <TextField
                    placeholder='Email'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                     
                    value={email}
                    onChange={handleEmail}
                    type='email'
                    error={Boolean(email) && !validateEmail(email)}
                    helperText={
                      Boolean(email) && !validateEmail(email)
                        ? "Invalid email"
                        : ""
                    }
                    sx={{ m: 0 }}
                  />
                </div>
                <div className='box-form__car-time'>
  <label>
    <i className='fa-regular fa-calendar-days'></i> &nbsp;Message
  </label>
  <TextField
    placeholder='Message'
    variant='outlined'
    name="message"
    margin='normal'
    onChange={handleMessage}
    fullWidth
     
    multiline
    rows={3}
    sx={{
      m: 0,
      borderRadius: "5px",
      backgroundColor: 'transparent',
      color: "#fff",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#ffffffba",  
        },
        "&:hover fieldset": {
          borderColor: "#0071ff",  
        },
        "& input, & textarea": {
          color: "#ffffff", 
        },
        "&.Mui-focused fieldset": {
          borderColor: "#0071ff",  
        },
      },
    }}

  />
</div>

                <div className='box-form__car-time'>
                  <label  >
                    <i className='fa-regular fa-calendar-days '></i> &nbsp;
                  </label>
                  <div className='search-btn'>
                    <button type='submit'>REQUEST A QUOTE  &nbsp; <FaArrowCircleRight /></button>
                  </div>
                </div>
              </form>
                </div>
            </div>
            </div>
        </section>
    );
}

export default BookCar;
